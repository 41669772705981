

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.v-expansion-panel::before {
  box-shadow: none;
}

.rounded-tabs .v-slide-group__wrapper {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
}
